import "./App.css";
import {useMeasure } from 'react-use';
import MobileApp from "./MobileApp";
import DesktopApp from "./DesktopApp";
import ReactGA from 'react-ga4';
import { useEffect } from "react";

const TRACKING_ID = 'UA-257708103-1';
//'UA-464087283-1'
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.send("pageview");
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  return (
    <div className={`container${width < 768 ? '-mobile' : ''}`} ref={ref}>
      {width < 768 ? <MobileApp /> : <DesktopApp />}
    </div>
  );
}

export default App;
