import "./DesktopApp.css";
import { useEffect, useState } from "react";
import { Button, Alert, Snackbar } from "@mui/material";
import { useMeasure } from "react-use";
import "animate.css";
import { ReactComponent as PPT } from "./assets/ppt.svg";
import { ReactComponent as S } from "./assets/backgrounds/s.svg";
import { ReactComponent as C } from "./assets/backgrounds/c.svg";
import { ReactComponent as I } from "./assets/backgrounds/i.svg";
import { ReactComponent as F } from "./assets/backgrounds/f.svg";
import { ReactComponent as G } from "./assets/backgrounds/g.svg";
import ReactGA from 'react-ga4';

const LetterMap = {
  s: [
    [38.42, 45, -10],
    [76.66, 5, 55],
    [28.91, 95, 75],
  ],
  c: [
    [-142.88, 5, -10],
    [-45.4, 95, -10],
    [-45.4, 20, 40],
    [88.72, 45, 85],
  ],
  i: [[0, 20, 80]],
  f: [
    [124.56, 75, -15],
    [42.26, 0, 30],
    [71.76, 40, 30],
    [-69.61, 75, 85],
  ],
  g: [
    [-56.66, 25, 0],
    [47.3, 95, 30],
    [47.3, 35, 60],
    [56.27, 0, 85],
  ],
};

const refWidth = 1366;
const refHeight = 768;

function DesktopApp() {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();
  const [scale, setScale] = useState(
    (width / refWidth + height / refHeight) / 2
  );
  const [networkErr, setNetworkErr] = useState(false);

  useEffect(() => {
    setScale((width / refWidth + height / refHeight) / 2);
  }, [width, height]);

  const downloadZip = async() => {
    ReactGA.event({'category': 'zip file download', 'action': '下载scifig安装包'});
    try {
      const downloadLink = document.createElement('a');
      downloadLink.href = 'https://scifig.com/static/scifig.zip';
      downloadLink.download = 'scifig.zip';
      downloadLink.click();
    } catch {
      setNetworkErr(true);
    }
    
  }

  const handleNetworkErrclose = () => {
    setNetworkErr(false);
  }

  return (
    <div className="App" ref={ref}>
      <div className="text-section">
        <div className="headline animate__animated animate__fadeInLeft">
          <span style={{ fontSize: 52, fontWeight: 500 }}>scifig</span>
          <div className="divider" />
          <span style={{ fontSize: 32 }}>让科技视觉创作更简单</span>
        </div>

        <div className="info animate__animated animate__fadeInLeft">
          <PPT />
          <div className="info-text">
            <span>首个PPT端面向生命科学的视觉创作工具</span>
            <span>scifig.bio 旨在为您提供高效的设计体验</span>
          </div>
        </div>

        <div className="download animate__animated animate__fadeIn">
          <a onClick={downloadZip}>
            <Button className="download-btn">
              下载 scifig.bio 安装包
            </Button>
          </a>
         <Snackbar open={networkErr} onClose={handleNetworkErrclose} autoHideDuration={3000}>
            <Alert severity="error" className="download-btn">
              网络出现错误
            </Alert>
         </Snackbar>

        </div>
        <div className="extended-info animate__animated animate__fadeIn">
          <div className="extended-text">
            <span>版本: 1.2.0</span>
            <span> | </span>
            <span><a href="scifig.bio插件更新日志.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转更新日志.html'})} className="extended-a">更新</a>: 2024.04.16</span>
          </div>
          
          <img src={'https://open.weixin.qq.com/qr/code?username=gh_6bcda748dc8b'} alt="wechat channel qr code" style={{ height: '200px', marginTop: '20px'}} />
          {/* <span style={{margin: 2, fontSize: '12px'}}>关注公众号，获取邀请码</span> */}
        </div>

        <div className="policy animate__animated animate__fadeIn">
          <span>使用前请仔细阅读
            <a href="版权申明.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转版权申明.html'})}>《版权规定》</a>、
            <a href="隐私政策.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转隐私政策.html'})}>《隐私政策》</a>、
            <a href="内测服务协议.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转内测服务协议.html'})}>《内测服务协议》</a>，
            下载或注册即视为同意签署内容 | 公安局备案编号：<a href='https://beian.miit.gov.cn/'>沪ICP备2021003888号</a>
          </span>
        </div>
      </div>

      <div className="material-section animate__animated animate__fadeIn">
        <div className="material-wrapper">
          <img alt="materials" src="group.png" className="materials one" />
          <img alt="materials" src="group.png" className="materials two" />
          <img alt="materials" src="group.png" className="materials three" />
          <img alt="materials" src="group.png" className="materials four" />
        </div>
      </div>

      {LetterMap.s.map((sStyle, idx) => (
        <S
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${sStyle[0]}deg) scale(${scale})`,
            left: `${sStyle[1]}%`,
            top: `${sStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.c.map((cStyle, idx) => (
        <C
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${cStyle[0]}deg) scale(${scale})`,
            left: `${cStyle[1]}%`,
            top: `${cStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.i.map((iStyle, idx) => (
        <I
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${iStyle[0]}deg) scale(${scale})`,
            left: `${iStyle[1]}%`,
            top: `${iStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.f.map((fStyle, idx) => (
        <F
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${fStyle[0]}deg) scale(${scale})`,
            left: `${fStyle[1]}%`,
            top: `${fStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.g.map((gStyle, idx) => (
        <G
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${gStyle[0]}deg) scale(${scale})`,
            left: `${gStyle[1]}%`,
            top: `${gStyle[2]}%`,
          }}
        />
      ))}
    </div>
  );
}

export default DesktopApp;
