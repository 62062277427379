import "./MobileApp.css";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useMeasure } from "react-use";
import "animate.css";
import { ReactComponent as PPT } from "./assets/ppt.svg";
import { ReactComponent as FEAT1 } from "./assets/feat1.svg";
import { ReactComponent as FEAT2 } from "./assets/feat2.svg";
import { ReactComponent as FEAT3 } from "./assets/feat3.svg";
import { ReactComponent as Headline } from "./assets/mobile/headline.svg";
import { ReactComponent as S } from "./assets/backgrounds/s.svg";
import { ReactComponent as C } from "./assets/backgrounds/c.svg";
import { ReactComponent as I } from "./assets/backgrounds/i.svg";
import { ReactComponent as F } from "./assets/backgrounds/f.svg";
import { ReactComponent as G } from "./assets/backgrounds/g.svg";
import ReactGA from 'react-ga4';

const LetterMap = {
  s: [
    [76.66, 10, 50],
  ],
  c: [
    [-45.4, 30, 40],
    [88.72, 70, 70],
    [123.5, 65, 90],
  ],
  i: [[0, 50, 60]],
  f: [
      [42.26, 0, 30],
      [71.76, 80, 25],
    [-69.61, 0, 65],
  ],
  g: [
    [47.3, 80, 50],
    [56.27, 5, 80],
  ],
};

const refWidth = 591;
const refHeight = 768;
const refPctWidth = 325 / refWidth;

function MobileApp() {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [scale, setScale] = useState(width / refWidth);
  useEffect(() => {window.scrollTo(0, 1);}, [])
  useEffect(() => {
    setScale(width / refWidth);
  }, [width]);

  const downloadZip = async() => {
    ReactGA.event({'category': 'zip file download', 'action': '下载scifig安装包'});
    try {
      const downloadLink = document.createElement('a');
      downloadLink.href = 'https://scifig.com/static/scifig.zip';
      downloadLink.download = 'scifig.zip';
      downloadLink.click();
    } catch {

    }
    
  }

  return (
    <div className="mobile-App" ref={ref}>
      <div className="mobile-material-section animate__animated animate__fadeIn">
        <div className="mobile-material-wrapper">
          <img
            alt="materials"
            src="group-mobile.png"
            className="mobile-materials one"
          />
          <img
            alt="materials"
            src="group-mobile.png"
            className="mobile-materials two"
          />
          <img
            alt="materials"
            src="group-mobile.png"
            className="mobile-materials three"
          />
          <img
            alt="materials"
            src="group-mobile.png"
            className="mobile-materials four"
          />
        </div>
      </div>
      <div className="mobile-text-section">
        <div className="within-first-page">
        <Headline
          className="mobile-headline animate__animated animate__fadeInLeft"
          style={{ width: `${refPctWidth * 100}%` }}
        />

        <div className="mobile-info animate__animated animate__fadeInLeft">
          <PPT />
          <span>首个PPT端面向生命科学领域的科技视觉创作工具</span>
        </div>
        </div>

        <div className="feat-container">
            <FEAT1 className="feat-icon" />
            <div className="feat-info">
                <span>便捷工具</span>
                <span>旨在为您提供高效的设计体验</span>
            </div>
        </div>

        <div className="feat-container">
            <FEAT2 className="feat-icon" />
            <div className="feat-info">
                <span>海量专业内容</span>
                <span>缩短您的创作周期，灵感不断</span>
            </div>
        </div>

        <div className="feat-container">
            <FEAT3 className="feat-icon" />
            <div className="feat-info">
                <span>持续更新</span>
                <span>精准AI工具实时获取，0门槛！</span>
            </div>
        </div>

        <div className="mobile-download animate__animated animate__fadeIn">
          <a onClick={downloadZip}>
            <Button className="download-btn">
              下载 scifig.bio 安装包
            </Button>
          </a>
        </div>
        
        <div className="extended-info animate__animated animate__fadeIn">
          <div className="extended-text">
            <span>版本: 1.1.0</span>
            <span> | </span>
            <span><a href="scifig.bio插件更新日志.html"
               onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转更新日志.html'})}
               className="extended-a">更新</a>: 2024.01.11
            </span>
          </div>
          
          <img src={'https://open.weixin.qq.com/qr/code?username=gh_6bcda748dc8b'} alt="wechat channel qr code" style={{ height: '200px', marginTop: '20px'}} />
          {/* <span style={{margin: 2, fontSize: '12px'}}>关注公众号，获取邀请码</span> */}
        </div>

        <div className="mobile-policy animate__animated animate__fadeIn">
          <span>
          <span>使用前请仔细阅读
          <a href="版权申明.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转版权申明.html'})} >《版权规定》</a>、
          <br/><a href="隐私政策.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转隐私政策.html'})} >《隐私政策》</a>、
          <a href="内测服务协议.html" onClick={() => ReactGA.event({'category': 'page redirection', 'action': '跳转内测服务协议.html'})} >《内测服务协议》</a>，
          <br/>下载或注册即视为同意签署内容
          <br/>公安局备案编号：<a href='https://beian.miit.gov.cn/'>沪ICP备2021003888号</a></span>
          </span>
        </div>
      </div>

      {LetterMap.s.map((sStyle, idx) => (
        <S
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${sStyle[0]}deg) scale(${scale})`,
            left: `${sStyle[1]}%`,
            top: `${sStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.c.map((cStyle, idx) => (
        <C
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${cStyle[0]}deg) scale(${scale})`,
            left: `${cStyle[1]}%`,
            top: `${cStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.i.map((iStyle, idx) => (
        <I
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${iStyle[0]}deg) scale(${scale})`,
            left: `${iStyle[1]}%`,
            top: `${iStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.f.map((fStyle, idx) => (
        <F
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${fStyle[0]}deg) scale(${scale})`,
            left: `${fStyle[1]}%`,
            top: `${fStyle[2]}%`,
          }}
        />
      ))}
      {LetterMap.g.map((gStyle, idx) => (
        <G
          className="bg-img"
          key={idx}
          style={{
            transform: `rotate(${gStyle[0]}deg) scale(${scale})`,
            left: `${gStyle[1]}%`,
            top: `${gStyle[2]}%`,
          }}
        />
      ))}
    </div>
  );
}

export default MobileApp;
